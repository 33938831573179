//Colours
$colbackground: #1b263b;
$colheaderfooter: #0d1b2a;
$coltext: #e5e5e5;
$colnavbutton: #778da9;

// start content

.header {
    position: fixed; 
    left: 0;
    top: 0;
    width: 100%;
    background-color: $colheaderfooter;
    color: $coltext;
    text-align: center;
}

body {
  background-color: $colbackground;
  font-family: 'Open Sans', sans-serif;
  color: $coltext;
  padding: 25px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $colheaderfooter;
  color: $coltext;
  text-align: center;
  display: flex;
  align-items: center;
} 

a:visited {
  color: $colnavbutton
}

//Nav Bar Styles

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky + .content {
  padding-top: 102px;
}

nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  
}

navli {
  float: left;
}

navli a nbutton{
  display: block;
  color: $coltext;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

navli a nbutton:hover {
  background-color:$colnavbutton;
  color: $coltext;
  
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  //width: 50%;
}

